import React, { useState } from 'react';

import { Trans } from '@lingui/macro';

import { DateTimeFormatter, LocalDate } from '@js-joda/core';

import { useQuery } from '@tanstack/react-query';

import Encoding from 'encoding-japanese';

import { groupBy } from '~/utils/groupBy';

import { workTodayFetcher } from './DashboardFetchers';
import { IDashboardWorkdayCrewData } from './models';
import { publish } from '~/utils/event';

import { axiosInstance as axios } from '~/axios';
import { clsx } from 'clsx';

const WorkTodayPortlet = <T,>(props: { tenantId: string } & React.HTMLAttributes<HTMLDivElement>) => {
  const { tenantId, className } = props;
  const [workDay, setWorkDay] = useState<LocalDate>(LocalDate.now());

  const {
    data: list,
    isPending,
    isError,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['dashboard-work-today', tenantId, workDay, window.userCode ?? ''],
    queryFn: ({ signal }) => workTodayFetcher(axios, tenantId, workDay, window.userCode ?? '', signal),
    enabled: tenantId != null && workDay != null,
    select: data =>
      groupBy(data?.data as Array<IDashboardWorkdayCrewData>, (d: IDashboardWorkdayCrewData) => d.scheduledTeamId),
  });

  return (
    <div className={className}>
      <div className="mx-2 mt-2 rounded-t-lg bg-[#4372C4] pl-4 font-anton text-[12pt] font-bold text-white">
        <Trans>Your Work Today</Trans>
      </div>
      <div className="min-h-[200px] mx-2 flex flex-col items-center justify-start rounded-b-lg bg-white p-2 font-body">
        <div className="flex flex-row items-center justify-center gap-4">
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setWorkDay(workDay.minusDays(1));
            }}>
            ◀︎
          </div>
          <div className="text-[10.5pt] text-gray-700">
            {workDay.format(DateTimeFormatter.ofPattern('yyyy年MM月dd日'))}
          </div>
          <div
            className="cursor-pointer select-none text-[12pt] text-gray-500"
            onClick={() => {
              publish('soundButtonClicked');
              setWorkDay(workDay.plusDays(1));
            }}>
            ▶︎
          </div>
        </div>
        {isPending ? null : isError ? (
          <div className="text-red-900"></div>
        ) : (
          <>
            {list != null && list.length > 0 ? (
              list.map((l: [string, IDashboardWorkdayCrewData[]]) => (
                <>
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="ml-[2.25rem] w-24 text-[9pt] text-blue-500">
                      <Trans>作業チーム：</Trans>
                    </div>
                    <div className="w-full text-left text-[9pt] text-gray-900">
                      {([...l[1]]?.pop()?.scheduledCrewAbbreviations ?? []).join(', ')}
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th className="w-8 text-center text-[9pt] text-blue-500">
                          <Trans>開始</Trans>
                        </th>
                        <th className="w-4 text-center text-[9pt] text-blue-500">&nbsp;</th>
                        <th className="w-8 text-center text-[9pt] text-blue-500">
                          <Trans>終了</Trans>
                        </th>
                        <th className="w-10 text-center text-[9pt] text-blue-500">
                          <Trans>時間</Trans>
                        </th>
                        <th className="w-24 text-left text-[9pt] text-blue-500">
                          <Trans>契約ID</Trans>
                        </th>
                        <th className="w-36 text-left text-[9pt] text-blue-500">
                          <Trans>物件名</Trans>
                        </th>
                        <th className="w-36 text-left text-[9pt] text-blue-500">
                          <Trans>作業内容</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {l[1].map((d: IDashboardWorkdayCrewData, index) => (
                        <tr key={index}>
                          <td className="text-center text-[9pt] text-gray-900">{d.workStartTime}</td>
                          <td className="text-center text-[9pt] text-gray-900">〜</td>
                          <td className="text-center text-[9pt] text-gray-900">{d.workEndTime}</td>
                          <td className="text-center text-[9pt] text-gray-900">
                            {d.workDuration?.toLocaleString('ja-JP', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            h
                          </td>
                          <td className="text-[9pt] text-gray-900">{d.contractCode}</td>
                          <td className="half-width-kana text-[9pt] text-gray-900">
                            {Encoding.toZenkanaCase(d.buildingAbbreviation ?? '')}
                          </td>
                          <td className="half-width-kana text-[9pt] text-gray-900">
                            {Encoding.toZenkanaCase(d.operationSummary ?? '')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                </>
              ))
            ) : (
              <div className="m-2 w-full p-2 text-left text-[10.5pt]">
                <Trans>本日の作業はありません</Trans>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WorkTodayPortlet;
